<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
        outlined
    >
      <v-toolbar-title>
        <v-btn
            href="/"
            text
        ><span class="mr-2">RHODES21</span>
        </v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>

<!--      <v-btn-->
<!--          href="/about"-->
<!--          text-->
<!--      >-->
<!--        <span class="mr-2">About</span>-->
<!--      </v-btn>-->

<!--      <v-btn-->
<!--          href="/contact"-->
<!--          text-->
<!--      >-->
<!--        <span class="mr-2">Contact</span>-->
<!--      </v-btn>-->

      <v-btn
          href="/spelling-bee"
          text
      >
        <span class="mr-2">Spelling Bee</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-card height="400px">
      <v-footer>
        <v-card
            flat
            tile
            width="100%"
            color="primary"
            class="lighten-1 text-center"
        >
          <v-card-text>
            <v-btn
                color="secondary"
                class="mx-4"
                icon
                href="/"
            >
              <v-icon size="24px">
                mdi-home
              </v-icon>
            </v-btn>
<!--            <v-btn-->
<!--                color="secondary"-->
<!--                class="mx-4"-->
<!--                icon-->
<!--                href="/contact"-->
<!--            >-->
<!--              <v-icon size="24px">-->
<!--                mdi-email-->
<!--              </v-icon>-->
<!--            </v-btn>-->
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            {{ new Date().getFullYear() }} — <strong>RHODES21</strong>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-card>
  </v-app>
</template>

<style>
main {
  background: linear-gradient(214deg, #007f5f 0%, #ffff3f 100%);
}
</style>

<script>
export default {
  name: 'App',

  data: () => ({}),
};
</script>
