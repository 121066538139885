<template>
  <v-container fill-height fluid>
    <v-row align="center"
           justify="center">
      <v-col align="center"><h1></h1></v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Home',
}
</script>
