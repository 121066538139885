import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({

  theme: {
    light: true,
    themes: {
      light: {
        primary: '#007f5f',
        secondary: '#ffff3f',
        tertiary: '#bfd200',
        accent: '#ffff00',
        error: '#c77c02',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#CCCCCC',
      }
    }
  },
});
